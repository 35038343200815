import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, catchError, map, of, takeUntil } from "rxjs";
import { IToken } from "../token.dto";
@Injectable({
  providedIn: "root",
})
export class RulibAuthService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private api_url = "https://api.lib.social";
  constructor(private http: HttpClient) {}

  setToken(token: string): Observable<boolean> {
    return this.checkToken(token).pipe(
      takeUntil(this.destroy$),
      map((data) => {
        if (!data) return false;
        const token_data: IToken = JSON.parse(localStorage.getItem("token") ?? "{}");
        token_data.rulib_token = token;
        localStorage.setItem("token", JSON.stringify(token_data));
        return true;
      }),
    );
  }

  getToken(): string {
    const token_data: IToken = JSON.parse(localStorage.getItem("token") ?? "{}");
    return token_data.rulib_token ?? "";
  }

  deleteToken(): void {
    const token_data: IToken = JSON.parse(localStorage.getItem("token") ?? "{}");
    token_data.rulib_token = "";
    localStorage.setItem("token", JSON.stringify(token_data));
  }

  checkToken(token: string): Observable<boolean> {
    return this.http
      .get(this.api_url + "/api/auth/me", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .pipe(
        takeUntil(this.destroy$),
        map(() => true),
        catchError(() => of(false)),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
