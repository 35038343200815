import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { NotificationService } from "../../services/notification/notification.service";
import { RulibAuthService } from "../../services/parsers/rulib/rulib.auth.service";
import { EAuthTokenService } from "./enum";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.less"],
  standalone: true,
  imports: [CommonModule, RouterLink],
})
export class AuthComponent implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @ViewChild("libSocialToken") libSocialToken: ElementRef<HTMLInputElement> | null = null;
  isRuLibAuth = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rulibAuthService: RulibAuthService,
    private notificationService: NotificationService,
  ) {}

  private setToken(service: EAuthTokenService, token: string) {
    switch (service) {
      case EAuthTokenService.RULIB:
        this.rulibAuthService
          .setToken(token)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            if (data) {
              this.router.navigate(["/"]);
              this.notificationService.info("Успешная авторизация в RuLib!", "Вход");
            } else {
              this.router.navigate(["/", "auth"]);
              this.notificationService.error(
                "Не удалось авторизоваться в RuLib! Попробуйте ещё раз.",
                "Вход",
                4000,
              );
            }
          });
        break;
      default:
        this.router.navigate(["/", "auth"]);
        this.notificationService.error("Неизвестный сервис авторизации!", "Вход");
        return;
    }
  }

  setLibSocialToken() {
    if (this.libSocialToken) {
      const token = this.libSocialToken.nativeElement.value;
      this.setToken(EAuthTokenService.RULIB, token);
    }
  }

  checkToken() {
    this.rulibAuthService
      .checkToken(this.rulibAuthService.getToken())
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isRuLibAuth = data;
      });
  }

  clearToken() {
    this.rulibAuthService.deleteToken();
    if (this.libSocialToken) this.libSocialToken.nativeElement.value = "";
    this.notificationService.info("Токен успешно удален!", "Вход");
  }

  ngAfterViewInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const { token, service } = params;
      if (token && service) {
        this.setToken(service as EAuthTokenService, token);
      }
    });
    if (this.libSocialToken) {
      this.libSocialToken.nativeElement.value = this.rulibAuthService.getToken();
    }
    this.checkToken();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
