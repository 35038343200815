import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppService } from "./app.service";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { TabBarComponent } from "./components/tab-bar/tab-bar.component";

@Component({
  standalone: true,
  imports: [
    RouterModule,
    HeaderComponent,
    NotificationComponent,
    NotificationComponent,
    FooterComponent,
    TabBarComponent,
  ],
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.less",
  providers: [AppService],
})
export class AppComponent {
  title = "NwaifuAnime";
}
