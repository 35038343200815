import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export abstract class Parser {
  constructor(protected http: HttpClient) {}

  protected abstract url: string;

  abstract searchManga(query: string): Observable<object>;

  abstract getDetails(slug_url: string): Observable<object>;

  abstract getChapters(url: string): Observable<object>;

  abstract getChapter(url: string, chapter: string, volume: string): Observable<object>;

  abstract getPopular(): Observable<object[]>;
}
