import { Route } from "@angular/router";
import { AuthComponent } from "./components/auth/auth.component";
import { DetailComponent } from "./components/detail/detail.component";
import { HomeComponent } from "./components/home/home.component";
import { ReaderComponent } from "./components/reader/reader.component";
import { DmcaComponent } from "./components/dmca/dmca.component";

export const appRoutes: Route[] = [
  {
    path: "",
    component: HomeComponent,
  },
  { path: "detail", component: DetailComponent },
  { path: "reader", component: ReaderComponent },
  {
    path: "auth",
    component: AuthComponent,
  },
  { path: "dmca", component: DmcaComponent },
];
