<div
  class="flex flex-row items-center justify-between me-4 max-w-[900px] relative -translate-x-[50%] left-[50%]"
>
  <a
    [routerLink]="['/', 'detail']"
    [queryParams]="{ url: url }"
    class="bg-slate-600 text-white rounded-lg p-3 m-4"
  >
    Назад к тайтлу
  </a>
  <h3>{{ currentChapterInfo?.number }}. {{ currentChapterInfo?.name || "Нет названия" }}</h3>
</div>
<div class="flex flex-col items-center">
  @if (pages.length > 0 && currentPage) {
    <div [class]="imageContainerClass">
      @if (!isManhwa) {
        <app-scale-image
          [imageSrc]="currentPage.imageUrl"
          (clickLeft)="prevPage()"
          (clickRight)="nextPage()"
        ></app-scale-image>
      } @else {
        @for (page of manhwaPages; track page.id) {
          <app-scale-image
            [imageSrc]="page.imageUrl"
            [host]="host"
            (view)="loadPage(page.index)"
            [hostScrollable]="isHostScrollable()"
          ></app-scale-image>
        }
      }
    </div>
    <div class="flex items-center justify-center space-x-4 mt-[6rem]">
      <button (click)="prevPage()" class="p-3 text-white bg-slate-600 w-[100px] rounded-lg">
        ←
      </button>
      <p *ngIf="!isManhwa">{{ pages[currentPageIndex].slug }} / {{ pages.length }}</p>
      <button (click)="nextPage()" class="p-3 text-white bg-slate-600 w-[100px] rounded-lg">
        →
      </button>
    </div>
    @if (isManhwa) {
      <div class="hidden md:block md:fixed right-10 top-[50%]">
        {{ currentPageIndex + 1 }} / {{ pages.length }}
      </div>
    }
  }
</div>
