<div class="flex flex-col m-6">
  <div class="mangalib-auth">
    <h2>Авторизация в MangaLib</h2>
    <ol class="list-decimal flex flex-col gap-4">
      <li>
        <p class="font-bold">Авторизация через токен</p>
        <div class="flex flex-row gap-4">
          <input
            type="text"
            placeholder="Token"
            class="outline-none border-md border px-2"
            #libSocialToken
          />
          <button
            type="button"
            (click)="setLibSocialToken()"
            class="hover:bg-slate-600 bg-slate-400 p-3 rounded-md text-white"
          >
            Сохранить
          </button>
          <button
            type="button"
            (click)="clearToken()"
            class="hover:bg-slate-600 bg-slate-400 p-3 rounded-md text-white"
          >
            Очистить
          </button>
        </div>
      </li>
      <li>
        Вход через скрипт TamperMonkey. Если нет кнопки, значит вы не установили скрипт
        <a
          href="https://test-front.mangalib.me"
          class="hover:bg-slate-600 bg-slate-400 p-3 rounded-md text-white tamperMonkey"
        >
          Вход
        </a>
      </li>
      <li>
        <div class="flex flex-row gap-3">
          <button
            type="button"
            (click)="checkToken()"
            class="hover:bg-slate-600 bg-slate-400 p-3 rounded-md text-white"
          >
            Проверить токен
          </button>
          <h3 class="self-center">{{ isRuLibAuth ? "Авторизован" : "Не авторизован" }}</h3>
        </div>
      </li>
    </ol>
  </div>
</div>
