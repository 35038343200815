import { Injectable } from "@angular/core";
import { ParserDecorator } from "../decorators/parser.decorator";
import { ESiteUrls } from "../urls";
import { LibSocialParserService } from "./lib.social.parser.service";

@Injectable({
  providedIn: "root",
})
@ParserDecorator({ site_name: "Slashlib", url: ESiteUrls.LIB_SOCIAL, site_id: 2 })
export class SlashlibParserService extends LibSocialParserService {}
