import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { IRulibChaptersResult } from "../../services/parsers/rulib/rulib.chapters.dto";
import { Data } from "../../services/parsers/rulib/rulib.detail.dto";
import { SearchService } from "../../services/search.service";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.less"],
  standalone: true,
  imports: [CommonModule, RouterLink],
})
export class DetailComponent implements AfterViewInit, OnDestroy {
  detail_item: Data | null = null;
  chapters: IRulibChaptersResult = { data: [] };
  needAuth: boolean = false;
  private destroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private router: Router,
  ) {}

  private getDetails(url: string) {
    this.searchService
      .getDetails(url)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.detail_item = data.data;
        },
        error: (error) => {
          console.log(error);
        },
      });
    this.searchService
      .getChapters(url)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.chapters = data;
          if (data.data.length === 0) {
            this.needAuth = true;
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  ngAfterViewInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const url = params["url"];
      if (url) {
        this.searchService.setMangalibParser();
        this.getDetails(url);
      } else {
        this.router.navigate(["/"]);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
