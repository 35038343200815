<p class="text-3xl ps-[3rem] mt-3">DMCA Disclaimer</p>
<div class="h-full px-[3rem] flex flex-col gap-3">
  <div class="block">
    <p>На русском:</p>
    <p>
      Разработчики этого приложения не имеют никакого отношения к контенту, доступному в приложении.
      Оно собрано из источников, свободно доступных через любой веб-браузер.
    </p>
  </div>
  <div class="block">
    <p>English:</p>
    <p>
      The developers of this application does not have any affiliation with the content available in
      the app. It is collecting from the sources freely available through any web browser.
    </p>
  </div>
</div>
