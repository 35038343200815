<div
  class="flex flex-row justify-start items-center md:hidden fixed bottom-0 w-full bg-gray-700 h-16 px-3"
>
  @for (link of links; track link.link) {
    <a [routerLink]="link.link">
      <div class="tab-bar__item flex flex-col justify-center items-center h-full center w-16">
        <i
          [class]="
            'text-3xl ' + link.line_icon + ' ' + (link.active ? 'text-white' : 'text-gray-500')
          "
        ></i>
        <span [class]="link.active ? 'text-white' : 'text-gray-500'">Auth</span>
      </div>
    </a>
  }
</div>
