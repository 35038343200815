import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { INotification } from "./notification.dto";

@Injectable({
  providedIn: "root",
})
export class NotificationService implements OnDestroy {
  readonly notification$ = new Subject<INotification>();

  private sendNotification(message: INotification) {
    this.notification$.next(message);
  }

  info(message: string, title: string = "", time = 3000) {
    this.sendNotification({
      message,
      isError: false,
      time: time,
      name: title,
    });
  }

  error(message: string, title: string = "", time = 3000) {
    this.sendNotification({
      message,
      isError: true,
      time: time,
      name: title,
    });
  }

  ngOnDestroy(): void {
    this.notification$.complete();
  }
}
