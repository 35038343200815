<div
  class="notification-container fixed md:right-0 bottom-0 max-w-[100%] min-w-[100%] min-h-[8rem] h-[8rem] md:min-w-[20rem] px-3 pb-4"
  [hidden]="!show_notification"
>
  <div [class]="'notification rounded-md bg-white border-2 ' + borderColor + ' h-full'">
    <div [class]="'head flex flex-row justify-between px-2 py-2 h-5 border-b-2 ' + borderColor">
      <h3 class="self-center">{{ notification.name }}</h3>
      <button class="self-center" (click)="closeNotification()" type="button">X</button>
    </div>
    <div class="notification-text mx-3 my-1">{{ notification.message }}</div>
  </div>
</div>
