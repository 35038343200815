import { provideHttpClient, withFetch } from "@angular/common/http";
import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from "@angular/core";
import { provideRouter } from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import { appRoutes } from "./app.routes";
import { Parser } from "./services/parsers/parser";
import { MangalibParserService } from "./services/parsers/rulib/mangalib.parser.service";
import { SlashlibParserService } from "./services/parsers/rulib/slashlib.parser.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideServiceWorker("ngsw-worker.js", {
      enabled: !isDevMode(),
      registrationStrategy: "registerWhenStable:30000",
    }),
    provideHttpClient(withFetch()),
    { provide: Parser, multi: true, useClass: MangalibParserService },
    { provide: Parser, multi: true, useClass: SlashlibParserService },
  ],
};
