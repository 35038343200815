export function ParserDecorator({
  site_name,
  url,
  nsfw = false,
  site_id = 1,
}: {
  site_name: string;
  url: string;
  nsfw?: boolean;
  site_id?: number;
}) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (constructor: Function) {
    constructor.prototype.site_name = site_name;
    constructor.prototype.nsfw = nsfw;
    constructor.prototype.api_url = url;
    constructor.prototype.site_id = site_id;
  };
}
