<div
  class="header fixed flex flex-col items-center px-2 justify-start gap-1 md:justify-between top-0 left-0 md:h-12 h-16 bg-gray-700 w-full md:flex-row"
>
  <a routerLink="/" title="Main page"><p class="text-xl text-white">NwaifuAnime</p></a>
  <app-search-field></app-search-field>
  <button title="Profile" #profileBtn class="hidden md:block">
    <div class="profile-btn bg-white rounded-full w-8 h-8 aspect-square ms-3">
      <img src="pic/Blank-profile.png" alt="Profile pic" class="w-full h-full" />
    </div>
  </button>
  @if (this.swEnabled && this.swHasUpdate) {
    <button title="Update" (click)="swUpdate()" class="md:w-fit w-full">
      <div
        class="h-8 md:w-8 w-full rounded-md bg-green-300 flex flex-col justify-center items-center md:ms-1 ms-0 md:mt-0 mt-2"
      >
        <i class="lni lni-download text-gray-700"></i>
      </div>
    </button>
  }
</div>
<div
  [class]="
    'profile-menu hidden md:flex flex-col overflow-hidden z-20 rounded-md w-32 bg-white border border-black p-3 h-full fixed ' +
    (menuOpened ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0')
  "
  #profileMenu
>
  <a routerLink="/auth" title="Auth page"><p class="hover:text-blue-400">Auth</p></a>
</div>
