import { CommonModule } from "@angular/common";
import { Component, OnDestroy } from "@angular/core";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { Subject, filter, takeUntil } from "rxjs";
import { ITab } from "./tab-bar.dto";

@Component({
  selector: "app-tab-bar",
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: "./tab-bar.component.html",
  styleUrl: "./tab-bar.component.less",
})
export class TabBarComponent implements OnDestroy {
  links: ITab[] = [
    {
      link: "/auth",
      line_icon: "lni lni-user",
      name: "Auth",
      active: false,
    },
  ];
  private destroy$ = new Subject<void>();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((event: any) => {
        this.links.forEach((link) => {
          link.active = event.url.startsWith(link.link);
        });
      });
  }
  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }
}
