<div class="flex flex-row gap-3 justify-between items-center md:justify-end">
  <div
    [class]="
      'input-block rounded-md bg-white transition-[width] delay-150 duration-300 ease-in-out h-full overflow-hidden flex justify-center items-center w-full ' +
      (openSearchField ? 'md:w-[300px]' : 'md:w-0')
    "
  >
    <input
      type="search"
      #searchInput
      class="outline-none ps-1 text-sm leading-6 w-full border-0 bg-transparent border-r border-gray-700"
      placeholder="Начать поиск"
      (keydown.enter)="search()"
    />
  </div>
  <button (click)="toggleSearchField()" class="hidden md:block">
    <div class="input-btn rounded-full w-8 h-8 bg-slate-400 flex justify-center items-center">
      <i class="lni lni-search-alt text-white"></i>
    </div>
  </button>
</div>
