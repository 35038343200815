import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { INotification } from "../../services/notification/notification.dto";
import { NotificationService } from "../../services/notification/notification.service";

@Component({
  standalone: true,
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.less"],
  imports: [CommonModule],
})
export class NotificationComponent implements AfterViewInit, OnDestroy {
  show_notification = false;
  isError = false;
  private closeNotificationTimer: ReturnType<typeof setTimeout> | null = null;
  notification: INotification = { isError: false, message: "", name: "", time: 0 };

  private destroy$ = new Subject<void>();

  constructor(private notificationService: NotificationService) {}

  ngAfterViewInit(): void {
    this.notificationService.notification$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!data) return;
        this.notification = data;
        this.show_notification = true;
        if (this.closeNotificationTimer) {
          clearTimeout(this.closeNotificationTimer);
        }
        this.closeNotificationTimer = setTimeout(() => {
          this.show_notification = false;
        }, data.time);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeNotification() {
    this.show_notification = false;
  }

  get borderColor(): string {
    return this.notification.isError ? "border-red-600" : "border-green-600";
  }
}
