import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { RouterLink } from "@angular/router";
import { Subject } from "rxjs";
import { AppService } from "../../app.service";
import { SearchFieldComponent } from "../search-field/search-field.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.less"],
  standalone: true,
  imports: [CommonModule, RouterLink, SearchFieldComponent],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  menuOpened = false;
  @ViewChild("profileBtn") profileBtn: ElementRef<HTMLButtonElement> | null = null;
  @ViewChild("profileMenu") profileMenu: ElementRef<HTMLDivElement> | null = null;
  private destroy$ = new Subject<void>();

  constructor(private swService: AppService) {}

  get swEnabled() {
    return this.swService.serviceWorkerEnabled;
  }

  swUpdate() {
    this.swService.update();
  }

  get swHasUpdate() {
    return this.swService.isUpdateAvailable;
  }

  @HostListener("window:click", ["$event"])
  toggleProfileMenu(event: MouseEvent) {
    if (this.profileBtn && this.profileBtn.nativeElement.contains(event.target as Node)) {
      this.menuOpened = !this.menuOpened;
      console.log(this.menuOpened);
    } else {
      this.menuOpened = false;
    }
  }

  ngAfterViewInit(): void {
    this.moveProfileMenu();
  }

  @HostListener("window:resize")
  moveProfileMenu() {
    if (this.profileBtn && this.profileMenu) {
      const btnRect = this.profileBtn.nativeElement.getBoundingClientRect();
      const menuRect = this.profileMenu.nativeElement.getBoundingClientRect();
      const menuWidth = menuRect.width;
      const menuX = btnRect.left + btnRect.width / 2 - menuWidth * 0.8;
      const menuY = btnRect.top + btnRect.height;
      this.profileMenu.nativeElement.style.left = `${menuX}px`;
      this.profileMenu.nativeElement.style.top = `calc(${menuY}px + 0.25rem)`;
    }
  }

  get menuBtnClass(): string {
    return `lni ${this.menuOpened ? "lni-close" : "lni-menu"} text-white`;
  }

  get searchBarClass(): string {
    return `search-bar bg-slate-300 md:w-full w-full md:m-0 ms-2 me-2 md:h-6 h-10 md:flex justify-start flex-row items-center rounded-md ${this.menuOpened ? "flex" : "hidden"}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
