import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { Parser } from "../parser";
import { RulibAuthService } from "./rulib.auth.service";
import { IRulibChapterResult } from "./rulib.chapter.dto";
import { IRulibChaptersResult } from "./rulib.chapters.dto";
import { IRulibDetailResult } from "./rulib.detail.dto";
import { IRuLIBPopular } from "./rulib.popular.dto";
import { IRulibSearchResult } from "./rulib.search.dto";

@Injectable({
  providedIn: "root",
})
export class LibSocialParserService extends Parser {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected url = (this as any).api_url;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private site_id = (this as any).site_id;
  get imageServer() {
    return "https://img33.imgslib.link";
  }

  constructor(
    private rulibAuthService: RulibAuthService,
    override http: HttpClient,
  ) {
    super(http);
  }

  searchManga(query: string): Observable<IRulibSearchResult> {
    return this.http
      .get(
        `${this.url}/api/manga?fields[]=rate_avg&fields[]=rate&q=${query}&site_id[]=${this.site_id}`,
      )
      .pipe(
        map((data: object) => {
          return data as IRulibSearchResult;
        }),
        catchError((error) => {
          return throwError(() => `Now found ${error}`);
        }),
      );
  }

  getDetails(slug_url: string): Observable<IRulibDetailResult> {
    return this.http
      .get(
        `${this.url}/api/manga/${slug_url}?fields[]=summary&fields[]=genres&fields[]=tags&fields[]=authors`,
        { headers: { Authorization: "Bearer " + this.rulibAuthService.getToken() } },
      )
      .pipe(
        map((data: object) => {
          return data as IRulibDetailResult;
        }),
        catchError((error) => {
          return throwError(() => `Now found ${error}`);
        }),
      );
  }

  getChapters(url: string): Observable<IRulibChaptersResult> {
    return this.http
      .get(`${this.url}/api/manga/${url}/chapters`, {
        headers: { Authorization: "Bearer " + this.rulibAuthService.getToken() },
      })
      .pipe(
        map((data) => {
          return data as IRulibChaptersResult;
        }),
        catchError((error) => {
          return throwError(() => `Now found ${error}`);
        }),
      );
  }

  getChapter(url: string, chapter: string, volume: string): Observable<IRulibChapterResult> {
    return this.http
      .get(`${this.url}/api/manga/${url}/chapter?number=${chapter}&volume=${volume}`, {
        headers: { Authorization: "Bearer " + this.rulibAuthService.getToken() },
      })
      .pipe(
        map((data) => data as IRulibChapterResult),
        catchError((error) => throwError(() => `Now found ${error}`)),
      );
  }

  isManhwa(slug_url: string): Observable<boolean> {
    return this.http
      .get(
        `${this.url}/api/manga/${slug_url}?fields[]=summary&fields[]=genres&fields[]=tags&fields[]=authors`,
      )
      .pipe(
        map((data) => {
          const res = data as IRulibDetailResult;
          return [5, 6, 4].includes(res.data.type.id);
        }),
        catchError((error) => throwError(() => `Now found ${error}`)),
      );
  }

  getPopular(): Observable<IRuLIBPopular[]> {
    return this.http
      .get<{ data: { popular: IRuLIBPopular[] } }>(`${this.url}/api/`, {
        headers: {
          Authorization: "Bearer " + this.rulibAuthService.getToken(),
          "Site-Id": this.site_id,
        },
      })
      .pipe(
        map((data) => {
          const res = data.data.popular;
          return res;
        }),
        catchError((error) => throwError(() => `Now found ${error}`)),
      );
  }
}
