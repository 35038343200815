<div
  class="footer w-full border-t border-black bg-gray-300 min-h-[3rem] mt-3 px-12 flex flex-row justify-between items-center"
>
  <div class="links flex flex-row flex-start gap-3 items-center">
    <a routerLink="/dmca" title="DMCA Disclaimer" class="underline">DMCA</a>
  </div>
  <a
    class="underline flex flex-row items-center gap-1"
    target="_blank"
    title="Source code"
    href="https://git.nwaifu.su/neuro_llc/NwaifuWeb/src/branch/dev/apps/NwaifuAnime"
    >Исходный код <i class="lni lni-git"></i
  ></a>
</div>
