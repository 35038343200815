<app-header></app-header>
<div class="md:h-12 h-16"></div>
<div
  class="content overflow-y-auto md:h-[calc(100vh-3rem)] h-[calc(100vh-4rem)] flex flex-col justify-between"
>
  <div class="h-max">
    <router-outlet></router-outlet>
  </div>
  <app-footer class="md:mb-0 mb-16 flex-shrink-0"></app-footer>
</div>
<app-tab-bar></app-tab-bar>
<app-notification></app-notification>
