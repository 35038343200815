import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject, filter, takeUntil } from "rxjs";

@Component({
  selector: "app-search-field",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./search-field.component.html",
  styleUrl: "./search-field.component.less",
})
export class SearchFieldComponent implements AfterViewInit, OnDestroy {
  @ViewChild("searchInput") searchInput: ElementRef<HTMLInputElement> | null = null;
  openSearchField = false;
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const search = params["search"];
      if (search && this.searchInput) {
        this.searchInput.nativeElement.value = search;
        this.openSearchField = true;
      }
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((event: any) => {
        if (event.url === "/" && this.searchInput) {
          this.searchInput.nativeElement.value = "";
          this.openSearchField = false;
        }
      });
  }

  search() {
    console.log(this.searchInput);
    if (this.searchInput && this.searchInput.nativeElement.value)
      this.router.navigate(["/"], {
        queryParams: { search: this.searchInput.nativeElement.value },
      });
  }

  toggleSearchField() {
    this.openSearchField = !this.openSearchField;
    if (this.openSearchField && this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
