@if (!search) {
  @if (loading) {
    <p class="text-5xl text-center mb-16">Подождите...</p>
  } @else {
    <p class="text-5xl text-center mb-10">Популярное:</p>
    <div class="flex flex-col gap-3 items-center w-full px-4 overflow-x-hidden">
      @for (popular of popularItems; track popular[0]) {
        <p class="text-5xl text-center">{{ popular[0] }}</p>
        <div class="w-full overflow-x-auto py-3">
          <div class="flex flex-row items-center gap-4 h-[400px] w-max">
            @for (popularTitle of popular[1]; track popularTitle.id) {
              <a routerLink="/detail" [queryParams]="{ url: popularTitle.slug_url }" class="h-full">
                <div
                  class="flex flex-col justify-around border-black rounded-md p-4 w-[400px] items-center border h-full flex-shrink-0"
                >
                  <img
                    [src]="popularTitle.cover.thumbnail"
                    [alt]="popularTitle.slug"
                    class="w-[200px] h-auto aspect-auto"
                  />
                  <p>{{ popularTitle.rus_name }}</p>
                </div>
              </a>
            }
          </div>
        </div>
      }
    </div>
  }
} @else {
  <div class="flex flex-col items-center w-full px-3 mt-3">
    @if (loading) {
      <h1>Loading...</h1>
    }
    @if (notFound && !loading) {
      <h1>Not found</h1>
    }
    @for (item of items; track $index) {
      <a
        routerLink="/detail"
        [queryParams]="{ url: item.slug_url }"
        title="{{ item.name }}"
        class="mb-6 max-w-[700px] w-full"
      >
        <div
          class="card flex flex-col items-center border-black border-2 rounded-md p-4 w-full h-[350px]"
        >
          <h1>{{ item.rus_name }}</h1>
          <img
            [src]="item.cover.thumbnail"
            [alt]="item.slug"
            class="w-[200px] h-auto max-h-[300px] aspect-auto"
          />
        </div>
      </a>
    }
  </div>
}
