<div class="flex flex-col items-center">
  @if (needAuth) {
    <h1>Необходима авторизация</h1>
    <a routerLink="/auth" class="text-center bg-slate-600 text-white p-3 rounded-md mb-4">Войти</a>
  }
  @if (detail_item) {
    <h1>{{ detail_item.name }}</h1>
    <h2>{{ detail_item.rus_name }}</h2>
    <img [src]="detail_item.cover.default" [alt]="detail_item.slug" />

    @if (!needAuth) {
      <details class="w-full">
        <summary class="text-center sticky top-0 bg-white z-10 py-2">
          <h3>Главы</h3>
        </summary>
        <div class="flex flex-col items-center pb-16">
          @for (chapter of chapters.data; track $index) {
            <a
              routerLink="/reader"
              [queryParams]="{
                url: detail_item.slug_url,
                chapter: chapter.number,
                volume: chapter.volume,
              }"
              [title]="chapter.name"
              class="p-3 text-white bg-slate-600 w-[300px] mt-3 rounded-lg"
            >
              <h3>
                <strong>{{ chapter.number }}.</strong> {{ chapter.name || "Нет названия" }}
              </h3>
            </a>
          }
        </div>
      </details>
      <a
        routerLink="/reader"
        class="p-3 text-white bg-slate-600 w-[300px] mt-5 rounded-lg text-center"
        [queryParams]="{ url: detail_item.slug_url, volume: 1, chapter: 1 }"
      >
        Читать
      </a>
    }
  }
</div>
