import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { map, Subject, Subscription, takeUntil } from "rxjs";
import { RulibAuthService } from "../../services/parsers/rulib/rulib.auth.service";
import { IRuLIBPopular } from "../../services/parsers/rulib/rulib.popular.dto";
import { Datum } from "../../services/parsers/rulib/rulib.search.dto";
import { SearchService } from "../../services/search.service";

@Component({
  standalone: true,
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.less"],
  imports: [CommonModule, RouterLink],
})
export class HomeComponent implements OnDestroy, OnInit {
  @Input() items: Datum[] = [];
  loading = false;
  notFound = false;
  search: string = "";
  popularItemsMap: Map<string, IRuLIBPopular[]> = new Map<string, IRuLIBPopular[]>();

  private destroy$ = new Subject<void>();
  private popularSubscription = new Subscription();

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private ruLibAuthService: RulibAuthService,
  ) {
    this.searchService.setMangalibParser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.popularSubscription.unsubscribe();
  }

  private getPopular(parserName: string) {
    this.searchService
      .getPopular()
      .pipe(
        map((data) => data as IRuLIBPopular[]),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.popularItemsMap.set(parserName, data);
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  private getPopularMangalib() {
    this.searchService.setMangalibParser();
    this.getPopular(this.searchService.getRuLibName());
  }

  private getPopularSlashlib() {
    this.ruLibAuthService
      .checkToken(this.ruLibAuthService.getToken())
      .pipe(takeUntil(this.destroy$))
      .subscribe((loggedIn) => {
        if (!loggedIn) return;
        this.searchService.setSlashlibParser();
        this.getPopular(this.searchService.getRuLibName());
      });
  }

  get popularItems() {
    return Array.from(this.popularItemsMap.entries()).sort((a, b) => (a[0] > b[0] ? 1 : -1));
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.search = params["search"] ?? "";
      this.items = [];
      this.loading = true;
      if (this.search) {
        this.searchService.setMangalibParser();
        this.searchService
          .search(this.search)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.loading = false;
            this.items = data.data;
            this.notFound = this.items.length === 0;
          });
      } else {
        this.getPopularMangalib();
        this.getPopularSlashlib();
      }
    });
  }
}
