import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { EParser } from "./parsers/enums/parser";
import { Parser } from "./parsers/parser";
import { IRulibChapterResult } from "./parsers/rulib/rulib.chapter.dto";
import { IRulibChaptersResult } from "./parsers/rulib/rulib.chapters.dto";
import { IRulibDetailResult } from "./parsers/rulib/rulib.detail.dto";
import { IRulibSearchResult } from "./parsers/rulib/rulib.search.dto";

@Injectable({ providedIn: "root" })
export class SearchService {
  private parser!: Parser;
  constructor(
    private http: HttpClient,
    @Inject(Parser) private parsers: Parser[],
  ) {}

  setMangalibParser() {
    this.setParser(EParser.MangaLib);
  }

  setSlashlibParser() {
    this.setParser(EParser.SlashLib);
  }

  private setParser(parser: EParser) {
    this.parser = this.parsers[parser];
  }

  search(query: string): Observable<IRulibSearchResult> {
    return this.parser.searchManga(query).pipe(
      map((data) => {
        return data as IRulibSearchResult;
      }),
    );
  }

  getDetails(slug_url: string): Observable<IRulibDetailResult> {
    return this.parser.getDetails(slug_url).pipe(
      map((data) => {
        return data as IRulibDetailResult;
      }),
    );
  }

  getChapters(url: string): Observable<IRulibChaptersResult> {
    return this.parser.getChapters(url).pipe(
      map((data) => {
        return data as IRulibChaptersResult;
      }),
    );
  }

  getChapter(url: string, chapter: string, volume: string): Observable<IRulibChapterResult> {
    return this.parser.getChapter(url, chapter, volume).pipe(
      map((data) => {
        return data as IRulibChapterResult;
      }),
    );
  }

  getImageServer() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.parser as any).imageServer;
  }

  getImageData(imageUrl: string): Observable<Uint8Array> {
    return this.http.get(imageUrl, { responseType: "arraybuffer" }).pipe(
      map((arrayBuffer: ArrayBuffer) => {
        return new Uint8Array(arrayBuffer);
      }),
    );
  }

  isManhwa(url: string): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.parser as any).isManhwa(url);
  }

  getPopular(): Observable<object[]> {
    return this.parser.getPopular();
  }

  getRuLibName(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.parser as any).site_name;
  }
}
