import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Injectable({ providedIn: "root" })
export class AppService {
  private hasUpdate = false;
  constructor(private sw: SwUpdate) {
    if (this.sw.isEnabled) {
      console.log("service init");
      this.sw.checkForUpdate();

      this.sw.versionUpdates.subscribe((ev) => {
        if (ev.type == "VERSION_DETECTED") {
          console.log("update detected");
          this.hasUpdate = true;
        } else {
          console.log("no update");
        }
      });
    }
  }

  get isUpdateAvailable() {
    return this.hasUpdate;
  }

  get serviceWorkerEnabled() {
    return this.sw.isEnabled;
  }

  update() {
    this.sw.activateUpdate().then((res) => {
      if (res) {
        console.log("updated");
        this.hasUpdate = false;
        window.location.reload();
      }
    });
  }
}
