import { Injectable } from "@angular/core";
import { ParserDecorator } from "../decorators/parser.decorator";
import { ESiteUrls } from "../urls";
import { LibSocialParserService } from "./lib.social.parser.service";

@Injectable({
  providedIn: "root",
})
@ParserDecorator({ site_name: "MangaLib", url: ESiteUrls.MANGALIB, site_id: 1 })
export class MangalibParserService extends LibSocialParserService {}
